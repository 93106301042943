


































.loading {
  position: fixed;
  inset: 0;

  display: flex;
  flex-direction: column;
  gap: 1.5em;
  justify-content: center;
  align-items: center;

  background: rgb(255 255 255 / 0.85);
}

.icon {
  animation: rotate 2.5s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}
