



































.logo {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 3em;

  margin-bottom: 3em;
}
