


























@use "../css/variables.scss";

.footer {
  margin-top: 4em;

  font-size: 0.9375em;
  color: variables.$color-gray-600;
}

.third-party-link {
  color: inherit;
}
