@use "variables.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: system-ui, sans-serif;
  line-height: 1.4;
  color: variables.$color-gray-900;
}

main {
  // Polyfill for older browsers.
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  font-family: inherit;
  font-size: 1em;

  border: 0;

  cursor: pointer;
}

label {
  cursor: pointer;
}

input,
textarea {
  font-family: inherit;
  line-height: 1;
  font-size: 1em;
}

textarea {
  line-height: 1.4;
}

[type="checkbox"],
[type="radio"] {
  cursor: pointer;
}

[hidden] {
  // Polyfill for older browsers.
  display: none;
}
